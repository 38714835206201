<script>
import crmHighIntend from '@/assets/images/crm_high_intend.svg'
import crmMidIntend from '@/assets/images/crm_mid_intend.svg'
import crmLowIntend from '@/assets/images/crm_low_intend.svg'
import {saveFollowup} from "@/api/crm/publicNew";

export default {
  name: "NewFollowUpPopup",
  data() {
    return {
      text: this.followUpContent,
      show: this.isShow,
      isEdit: false,
      crmHighIntendIcon: crmHighIntend,
      crmMidIntendIcon: crmMidIntend,
      crmLowIntendIcon: crmLowIntend,
      followTips: ['未接通', '主动挂机', '通话中', '现在不方便，稍后回电话', '加微信', '预约来访'],
      mustNeedEditKey: ['intentionDegree'],
      onlyOneEditKey: ['intentionDegree', 'age', 'workingState', 'familyCondition'],
      stateList: [],
      isClick: false,
      // 字段信息
      extra_filed_info: [],
      follow_record_data: {
        "intentionDegree": 0, // 当前选中的意向等级 1 低 2 中 4 高
        "currentSymptom": [],
        "age": [],
        "workingState": [],
        "medicalDiagnosisState": [],
        "familyCondition": [],
      },
      crmClueData: {},
      currentStageName: '',
      resourceType: 'CLUE',
    };
  },
  methods: {
    initData(show, extra_filed_info, follow_record_data, isEdit, crmClueData) {
      this.show = show;
      this.extra_filed_info = extra_filed_info;
      // this.follow_record_data = follow_record_data.extraInfo;
      var hasExtraInfo = false;
      if (follow_record_data) {
        if (follow_record_data.extraInfo) {
          this.follow_record_data = follow_record_data.extraInfo;
          hasExtraInfo = true;
        }
      }
      if (!hasExtraInfo) {
        this.follow_record_data = {
          "intentionDegree": 0, // 当前选中的意向等级 1 低 2 中 4 高
          "currentSymptom": [],
          "age": [],
          "workingState": [],
          "medicalDiagnosisState": [],
          "familyCondition": [],
        }
      }
      this.text = follow_record_data.infoFollow !== undefined ? follow_record_data.infoFollow.content : '';
      this.isEdit = isEdit;
      this.crmClueData = crmClueData;
      this.stateList = [];
      this.currentStageName = crmClueData.currentStageName;
      this.resourceType = crmClueData.resourceType;
      if (this.currentStageName === "待跟进") {
        this.initDict();
      }
    },
    async initDict() {
      const dict = await this.$getDict();
      console.log(dict);
      if (this.resourceType === "CLUE") {
        dict.followStage.children.forEach(item => {
          this.stateList.push({
            id: item.code,
            name: item.name,
            checked: false,
          });
        });
      }
      if (this.resourceType === "BUSINESS") {
        dict.followStageBus.children.forEach(item => {
          this.stateList.push({
            id: item.code,
            name: item.name,
            checked: false,
          });
        });
      }
    },
    addTipTagContentToText(content) {
      if (this.text !== '') {
        this.text = this.text + ' ' + content;
      } else {
        this.text = content;
      }
    },
    close() {
      this.show = false;
      this.$emit("close");
    },
    save() {
      if (!this.text) {
        this.$toast("跟进记录不能为空！");
        return;
      }
      if (this.currentStageName === "待跟进") {
        if (!this.follow_record_data.state) {
          this.$toast("请选择一个阶段状态！");
          return;
        }
      }
      if (this.follow_record_data.intentionDegree === 0 || this.follow_record_data.intentionDegree === undefined) {
        this.$toast("请选择一个意向等级！");
        return;
      }
      const submitData = {
        content: this.text,
        resourceId: this.crmClueData.resourceId,
        id: '',
        crmEnum: this.resourceType,
        resourceType: this.resourceType === 'CLUE' ? 1 : 2,
        state: this.follow_record_data.state,
        extraInfo: {
          workingState: this.follow_record_data.workingState,
          currentSymptom: this.follow_record_data.currentSymptom,
          age: this.follow_record_data.age,
          medicalDiagnosisState: this.follow_record_data.medicalDiagnosisState,
          familyCondition: this.follow_record_data.familyCondition,
          intentionDegree: this.follow_record_data.intentionDegree,
          resourceId: this.crmClueData.resourceId,
          resourceType: this.resourceType === 'CLUE' ? 1 : 2,
        }
      };

      //防止短时间内多次点击
      if (this.isClick) {
        return;
      }
      this.isClick = true;
      saveFollowup(submitData).then(res => {
        if (res.success) {
          this.$toast.success("保存成功！");
          this.show = false;
          this.$emit('onLoad', true);
          this.text = "";
          this.isClick = false;
        } else {
          this.$toast.fail(res.message);
          this.isClick = false;
        }
      });
    },
    // 设置follow_record_data，触发Vue更新
    setFollowRecordData(key, value) {
      if (!this.isEdit) {
        return;
      }
      // 拷贝原先
      let follow_record_data = JSON.parse(JSON.stringify(this.follow_record_data));
      // 设置
      follow_record_data[key] = value;
      // 更新
      this.follow_record_data = follow_record_data;
    },
    toggleFollowRecordDataField(key, value) {
      if (!this.isEdit) {
        return;
      }
      var follow_record_data = null;
      // 拷贝原先
      follow_record_data = JSON.parse(JSON.stringify(this.follow_record_data));

      // 判断是否存在
      if (follow_record_data[key] != null) {
        // 存在
        let index = follow_record_data[key].indexOf(value);
        if (index > -1) {
          // 存在，删除
          follow_record_data[key].splice(index, 1);
        } else {
          // 不存在，添加
          if (this.onlyOneEditKey.indexOf(key) !== -1) {
            follow_record_data[key] = [];
          }
          follow_record_data[key].push(value);
        }
      } else {
        // 不存在，添加
        follow_record_data[key] = [value];
      }
      // 更新
      this.follow_record_data = follow_record_data;
    },
  },
}
</script>

<template>
  <van-popup
      v-model="show"
      position="bottom"
      closeable
      round
      :style="{ height: 'auto' }">
    <div class="popup-content">
      <div class="popup-header">
        <div class="popup-header-title">{{
            isEdit ? stateList.length ? '变更阶段和写跟进' : '写跟进' : "查看跟进记录"
          }}
        </div>
      </div>
      <!--    遍历extra_filed_info-->
      <div class="popup-field-group">
        <template v-for="(item, index) in extra_filed_info">
          <van-cell v-if="item.key==='intentionDegree'" :key="index" center :title=item.title
                    title-class="intention-title">
            <div class="intend_select_group">
              <template v-for="(intendField,index) in item.fieldData">
                <div class="intend_select_item"
                     :class="{ 'intend_select_item_active': follow_record_data['intentionDegree'] === intendField.bitValue }"
                     :key="index"
                     @click="setFollowRecordData('intentionDegree',intendField.bitValue)"
                >
                  <div class="intend_select_item_content">
                    <!--                    <div-->
                    <!--                        :class="{ 'intend_select_item_text': true, 'intend_select_item_text_active': follow_record_data['intentionDegree'] === intendField.bitValue }">-->
                    <!--                      {{ intendField.name }}-->
                    <!--                    </div>-->
                    <img class="img-icon" v-if="intendField.bitValue===4"
                         :class="{ 'intend_select_item_icon': true, 'intend_select_item_icon_active': follow_record_data['intentionDegree'] === intendField.bitValue }"
                         :src="crmHighIntendIcon"
                    />
                    <img class="img-icon" v-else-if="intendField.bitValue===2"
                         :class="{ 'intend_select_item_icon': true, 'intend_select_item_icon_active': follow_record_data['intentionDegree'] === intendField.bitValue }"
                         :src="crmMidIntendIcon"
                    />
                    <img class="img-icon" v-else-if="intendField.bitValue===1"
                         :class="{ 'intend_select_item_icon': true, 'intend_select_item_icon_active': follow_record_data['intentionDegree'] === intendField.bitValue }"
                         :src="crmLowIntendIcon"
                    />
                  </div>
                </div>
              </template>
            </div>
          </van-cell>
          <van-cell v-else :key="index" :title=item.title>
            <div class="field-tag-group">
              <template v-for="(field,index) in item.fieldData">
                <div
                    :key="index"
                    :class="{ 'intend_select_tag': true, 'intend_select_tag_active': follow_record_data[item.key]!=null?follow_record_data[item.key].indexOf(field.bitValue) > -1:false }"
                    @click="toggleFollowRecordDataField(item.key,field.bitValue)"
                >
                  {{ field.name }}
                </div>
              </template>

            </div>
          </van-cell>
          <!--          分割线-->
          <van-divider
              :key="index+'divider'"
              hairline
              :style="{ margin: '0 15px 0px 15px',borderColor: '#EEEEEE' }"
          />
        </template>
      </div>
      <template v-if="isEdit">
        <van-cell v-if="stateList.length" title="变更阶段" title-class="state-title">
          <div class="field-tag-group">
            <template v-for="(item,index) in stateList">
              <template v-if="item.id !== '-1'">
                <div
                    :key="index"
                    :class="{ 'intend_select_tag': true, 'intend_select_tag_active': follow_record_data['state']!=null?follow_record_data['state'].indexOf(item.id) > -1:false }"
                    @click="setFollowRecordData('state',item.id)"
                >
                  {{ item.name }}
                </div>
              </template>
            </template>
          </div>
        </van-cell>
        <van-divider
            :key="index+'divider'"
            hairline
            :style="{ margin: '0 15px 0px 15px',borderColor: '#EEEEEE' }"
        />
      </template>
      <div class="popup-little-title" style="margin: 10px 0px 0px 0;padding-left: 0.5rem">
        {{
          isEdit ? "添加跟进记录" : "跟进记录"
        }}
      </div>
      <div style="padding-left: 0.5rem;padding-right: 0.5rem;padding-top: 0.3125rem;padding-bottom: 0.3125rem">
        <van-field
            v-model="text"
            :autosize="{ minHeight: 100, maxHeight: 200 }"
            type="textarea"
            :placeholder="isEdit?'请输入跟进记录':'暂无跟进记录'"
            maxlength="1000"
            :disabled="!isEdit"
            show-word-limit
        />
      </div>
      <template v-if="isEdit">
        <!--
        未接通
        主动挂机
        通话中
        现在不方便，稍后回电话
        加微信
        预约来访-->
        <div class="tip-tag-group">
          <template v-for="(tipContent) in followTips">
            <div :key="tipContent" @click="addTipTagContentToText(tipContent)" class="tip-tag">{{ tipContent }}</div>
          </template>
        </div>
      </template>
    </div>
    <div class="popup-footer">
      <van-button :color="isEdit?'#FFFFFF':'#205AF9'"
                  block
                  square
                  style="width: 100%"
                  :style="{ color: isEdit?'#000000':'#FFFFFF' }"
                  @click="close">取消
      </van-button>
      <van-button square v-if="isEdit" color="#1b42e5" block style="width: 100%" @click="save">保存</van-button>
    </div>
  </van-popup>
</template>

<style scoped>
.popup-header-title {
  margin-top: 15px;
  color: var(--subTitleColor);
  font-size: 17px;
  text-align: center;
}

.van-popup {
  overflow-x: hidden;
}

.van-field {
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

/deep/ .van-field__word-limit {
  color: var(--subTitleColor);
}

/deep/ .van-radio-group {
  margin-left: 0.5rem;
}

/deep/ .popup-footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e5e5e5;
}

.intend_select_item_text {
  height: 0.65rem;
  overflow: hidden;
  color: #F18D33;
  /*不显示字*/
  opacity: 0;
  /*如果是safari 解决底部被遮挡*/
  transition: opacity 0.25s;
}


.intend_select_item_text_active {
  opacity: 1;
  color: #F18D33;
}


.van-button {
  background: var(--mainColor);
  border: var(--mainColor);
}

.field-tag-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  /*row-gap: 0.3rem;*/
  /*column-gap: 0.2rem;*/
  margin-right: 0.5rem;
  margin-left: 0.25rem;
  margin-bottom: -0.3rem;
}

.intend_select_tag {
  /*左右7px，上下2px，内部白色字11px*/
  padding: 5px 8px;
  border-radius: 3px;
  color: #000000;
  background: #eeeeee;
  font-size: 12px;
  min-width: 1.2rem;
  margin-right: 0.2rem;
  margin-bottom: 0.3rem;
  text-align: center;
  /*  过渡动画*/
  transition: all 0.15s;
}

.intend_select_tag_active {
  background: #d3dbff;
}

.state-title {
}

.state-title::before {
  content: "*";
  color: red;
  position: absolute;
  width: 0.2168rem;
  margin-right: 0.0625rem;
  left: 0.25rem;
  font-size: 0.53125rem;
}

/*van-cell--required::before*/
.van-cell--required::before {
  content: "*";
  color: red;
  font-size: 17px;
  margin-right: 2px;
}

.tip-tag-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  padding-right: 0.5rem;
  /*row-gap: 0.3rem;*/
  /*column-gap: 0.2rem;*/
  margin-left: 0.5rem;
  margin-bottom: -0.15rem;
}

.tip-tag {
  /*左右7px，上下2px，内部白色字11px*/
  padding: 5px 8px;
  border-radius: 3px;
  color: #000000;
  background: #eeeeee;
  font-size: 12px;
  min-width: 1.2rem;
  text-align: center;
  margin-right: 0.1rem;
  margin-bottom: 0.35rem;
  /*  过渡动画*/
  transition: all 0.15s;
}


.img-icon {
  width: 46px;
  height: 46px;
}

.intend_select_item_icon {
  opacity: 0.2;
  transition: opacity 0.25s;
}

.intend_select_item_icon_active {
  opacity: 1;
}

.van-cell__title {
  font-size: 0.5rem;
  letter-spacing: -0.5px;
  font-family: "Noto Sans SC", serif;
  display: flex;
  align-items: center;
}

.intention-title {
  /*margin-top: 0.65rem;*/
  flex: 0.6;
}

.intention-title:before {
  content: "*";
  color: red;
  position: absolute;
  width: 6.9375px;
  margin-right: 0.0625rem;
  left: 0.25rem;
  font-size: 0.53125rem;
}


.van-cell__value {
  flex: auto;
  max-width: 80%;
}

.van-cell {
  justify-content: space-between;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.intend_select_item_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intend_select_group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.intend_select_item {
  /*  水平居中*/
  display: flex;
  /*margin-right: 15px;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-little-title {
  font-size: 16px;
  font-family: "Noto Sans SC", serif;
}

.popup-little-title:before {
  content: "*";
  color: red;
  position: absolute;
  width: 6.9375px;
  margin-right: 0.0625rem;
  left: 0.25rem;
  font-size: 0.53125rem;
}


/*//自定义van-tag*/
.van-tag {
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  background-color: var(--mainColor);
}
</style>
