<template>
  <div class="card-list">
    <div class="subtitle-body" v-if="isOrg && listData.length > 0">
      <div class="subtitle-warp">{{ listData[0].orgName }}</div>
    </div>
    <div v-for="(panelItem, panelIndex) in listData" :key="panelIndex" :data-id="panelItem.id">
      <div class="subtitle" v-if="isShowOrgName(panelItem.orgName)" :data-id="panelItem.id">
        {{ panelItem.orgName }}
      </div>
      <div class="card-panel" @click.stop="panelItem.forward(panelItem)">
        <div class="card-warp">
          <div class="card-left" v-if="panelItem.image">
            <van-image :fit="panelItem.image.fit ? panelItem.image.fit : 'cover'"
                       :src="panelItem.image.url"/>
            <span v-if="panelItem.image.title">{{ panelItem.image.title }}</span>
          </div>
          <div class="card-right">
            <div class="card-state">{{ panelItem.state }}</div>
            <div class="card-title-group">
              <div class="card-title">
                {{
                  panelItem.title.format
                      ? panelItem.title.format(panelItem.title.value)
                      : panelItem.title.value
                }}
              </div>
              <template v-if="panelItem.extraInfo">
                <intension-icon :extra-info="panelItem.extraInfo" :icon-size="1"></intension-icon>
              </template>
              <!--              <div class="name">{{ panelItem.extraInfo }}</div>-->
            </div>
            <div class="card-subtitle" v-if="panelItem.subtitle">
              {{ panelItem.subtitle }}
            </div>
            <div class="card-phone" v-if="panelItem.phone">
              {{
                panelItem.phone.format
                    ? panelItem.phone.format(panelItem.phone.value)
                    : panelItem.phone.value
              }}
              <span @click.stop="copy(panelItem.phone.value)">复制</span>
              <template v-if="panelItem.stateName">
                <span style="color: #000000">
                  ({{ panelItem.stateName }})
                </span>
              </template>
            </div>
            <div class="card-content">
              <ul>
                <li>
                  <template v-if="panelItem.tags">
                    <template v-for="(tagItem, tagIndex) in panelItem.tags">
                      <van-tag :key="tagIndex" class="tag ts1" type="success" v-if="tagItem">
                        {{ tagItem }}
                      </van-tag>
                    </template>
                  </template>
                </li>
                <li>
                  <template v-if="panelItem.source">
                    <template v-for="(tagItem, tagIndex) in panelItem.source">
                      <van-tag :key="tagIndex" class="tag ts2" type="success" v-if="tagItem">
                        {{ tagItem }}
                      </van-tag>
                    </template>
                  </template>
                </li>
                <li v-for="(contentItem, contentIndex) in panelItem.content" :key="contentIndex">
                  <template v-if="Array.isArray(contentItem)">
                                        <span v-for="(c2Item, c2Index) in contentItem" :key="c2Index">
                                            <template v-if="c2Item.value">{{ c2Item.name }}{{ c2Item.value }}</template>
                                        </span>
                  </template>
                  <template v-else-if="contentItem.value">
                    {{ contentItem.name }}{{ contentItem.value }}
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-footer" v-if="panelItem.footer">
          <div class="card-footer-left" v-if="panelItem.footer.content" v-html="panelItem.footer.content">
          </div>
          <div class="card-footer-right">
            <template v-for="(buttonItem, buttonIndex) in panelItem.footer.buttons">
              <template v-if="buttonItem.type == 'link'">
                <van-button class="btn" :class="buttonItem.style ? buttonItem.style : 'bs1'" type="info"
                            size="mini" :key="buttonIndex" :url="buttonItem.url">
                  {{ buttonItem.name }}
                </van-button>
              </template>
              <template v-if="buttonItem.type == 'event'">
                <van-button class="btn" :class="buttonItem.style ? buttonItem.style : 'bs1'" type="info"
                            size="mini" :key="buttonIndex" @click.stop="buttonItem.event(panelItem)">
                  {{ buttonItem.name }}
                </van-button>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crmHighIntend from '@/assets/images/crm_high_intend.svg'
import crmMidIntend from '@/assets/images/crm_mid_intend.svg'
import crmLowIntend from '@/assets/images/crm_low_intend.svg'
import IntensionIcon from "@/components/common/IntensionIcon";

export default {
  components: {IntensionIcon},
  props: {
    listData: {
      required: true,
    },
    orgNameMaps: null,
    isOrg: {},
  },
  data() {
    return {
      zIndex: 5,
      subTitleName: "",
      crmHighIntend: crmHighIntend,
      crmMidIntend: crmMidIntend,
      crmLowIntend: crmLowIntend,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    fromBitValueGetIcon(intend) {
      if (intend === 4) {
        return this.crmHighIntend;
      } else if (intend === 2) {
        return this.crmMidIntend;
      } else if (intend === 1) {
        return this.crmLowIntend;
      }
    },
    handleScroll() {
      if (this.isOrg) {
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; // 滚动条偏移量
        var subtitleList = document.querySelectorAll(".subtitle");
        subtitleList.forEach((item) => {
          let offsetTop = item.offsetTop;
          if (scrollTop > offsetTop) {
            var name = item.innerHTML;
            document.querySelector(".subtitle-warp").innerHTML = name;
          }
        });
      }
    },
    isShowOrgName(orgName) {
      if (this.orgNameMaps != null) {
        if (this.orgNameMaps[orgName]) {
          return false;
        } else {
          this.orgNameMaps[orgName] = orgName;
          return true;
        }
      }
    },
    titleClick(item) {
      if (item.titleUrl) {
        this.$router.push(item.titleUrl);
      }
    },
    copy(value) {
      this.$copyText(value).then(
          () => {
            this.$toast("已复制");
          },
          () => {
            this.$toast("复制失败");
          }
      );
    },
  },
};
</script>

<style lang="less">
.card-list {
  margin: 0px 2.6%;

  .card-panel {
    background: var(--cardBackgroundColor);
    border-radius: 5px;
    padding: 15px 15px 15px 15px;
    margin-top: 7px;
    margin-bottom: 7px;

    .card-warp {
      display: flex;
      flex-wrap: nowrap;

      .card-state {
        position: absolute;
        color: #F18D33;
        right: 0px;
        top: 0px;
      }

      .card-left {
        max-width: 30%;
        border-radius: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        //justify-content: center;
        flex-direction: column;
        padding: 1%;

        img {
          width: 100%;
          border-radius: 5px;
        }

        span {
          display: inline-block;
          margin-top: 5px;
          font-size: 12px;
          color: #666;
        }
      }

      .card-right {
        width: 100%;
        position: relative;

        .card-title-group {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .card-title {
          font-size: 20px;
          width: auto;
          padding-right: 0.2rem;
          color: #000;
          font-weight: bold;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: relative;
        }

        .card-subtitle {
          margin-top: 5px;
          font-size: 14px;
          color: #666;
        }

        .card-phone {
          margin-top: 15px;
          font-size: 14px;
          color: #999;

          span {
            color: #3c71f1;
            margin-left: 5px;
          }
        }

        .card-content {
          font-size: 14px;

          li {
            margin-top: 10px;

            span {
              margin-right: 10px;
              white-space: nowrap;

              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }

        .tag {
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          padding: 3px 5px;
        }

        .ts1 {
          color: #163ae9;
          border: 1px solid #163ae9;
          background: #fff;
        }

        .ts2 {
          color: #000;
          border: 1px solid #d3dbff;
          background: #d3dbff;
        }
      }
    }

    .card-footer {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      // 先让right的按钮靠右 width自适应，left的按钮靠左 占据剩余的宽度
      .card-footer-left {
        flex: 1.3;
        text-align: left;
      }

      .card-footer-right {
        flex: 1;
        text-align: right;
        // 不换行
      }

      button {
        margin-left: 7px;
      }
    }
  }
}

.btn {
  padding: 14px 12px;
  border-radius: 20px;

  .van-button__content {
    font-size: 12px !important;
  }
}

.bs1 {
  background: #163ae9;
  border: 1px solid #163ae9;
}

.bs2 {
  background: #fff;
  border: 1px solid #ccc;
  color: #676767;
}

.subtitle {
  background: #f2f3f4;
  width: 100%;
  z-index: 5;
  padding: 3px 8px;
}

.subtitle-body {
  .subtitle-warp {
    position: fixed;
    z-index: 5;
    width: 100%;
    height: auto;
    background: #f2f3f4;
    padding: 3px 8px;
  }
}
</style>
