<template>
  <div style="display: flex; flex-direction: column; height: 100%;">
    <template v-if="extraInfo">
      <template v-if="intention!=null">
        <img :src="fromBitValueGetIcon(intention)" alt="" :style="iconStyle">
      </template>
    </template>
  </div>
</template>

<script>
import crmHighIntend from '@/assets/images/crm_high_intend.svg'
import crmMidIntend from '@/assets/images/crm_mid_intend.svg'
import crmLowIntend from '@/assets/images/crm_low_intend.svg'

export default {
  name: "IntensionIcon",
  props: {
    extraInfo: {
      type: Object,
      default: null
    },
    iconSize: {
      type: Number,
      default: 0.5
    }
  },
  data() {
    return {
      iconStyle: {
        width: this.iconSize + "rem",
        height: this.iconSize + "rem"
      },
      crmHighIntend: crmHighIntend,
      crmMidIntend: crmMidIntend,
      crmLowIntend: crmLowIntend
    }
  },
  methods: {
    fromBitValueGetIcon(bitValue) {
      if (bitValue === 4) {
        return this.crmHighIntend
      } else if (bitValue === 2) {
        return this.crmMidIntend
      } else if (bitValue === 1) {
        return this.crmLowIntend
      } else {
        return null
      }
    }
  },
  computed: {
    intention() {
      if (this.extraInfo) {
        if (this.extraInfo.intention) {
          return this.extraInfo.intention
        } else if (this.extraInfo.intentionDegree) {
          return this.extraInfo.intentionDegree
        } else {
          return null
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
